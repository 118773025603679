import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import {Button, Form, Container, Row, Col,Dropdown} from 'react-bootstrap';
// import { BackParentMenu } from "../../../queries/common_use_query";
import { useStaticQuery, graphql } from "gatsby"

// markup
const Breadcrumbs = (props ) => {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        allMenus(sort:"Sorting:asc") {
          id
          Label
          Primary_URL
          Secondary_URL
          Show_In_Top_Nav
          Main_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
        }
      }
    }
  `)
    var menu_data = data.glstrapi.allMenus;
    if (typeof window !== "undefined") {
        var pageURL = window.location.href.split("#")[0] ? window.location.href.split("#")[0] : '';
        var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
        var currenturl = props.alias?props.alias:removeSlash.substr(removeSlash.lastIndexOf('/') + 1);
        currenturl = currenturl.replace(/\?.+/, '')
        if(currenturl === "send-to-friend" || currenturl === "book-a-viewing") {
          currenturl=""
        }
    }    
    //const {loading, error, data} = BackParentMenu(currenturl);
    return ( 
        <React.Fragment>
           <div className={`back-block breadcrumbs ${props.style}`}>
               
                           <div className="back-wrap">
                                <Link to="/" className="back-btn">Home<span> . </span></Link>
                                {menu_data && menu_data.length > 0 && menu_data.map((menu, index) => {
                                return<>        
                                {menu.Main_Parent === null && menu.Sub_Parent === null && menu.Primary_URL === currenturl &&
                                <Link  className="back-btn">{menu.Label}</Link>
                                }                        
                                {menu.Main_Parent && menu.Sub_Parent === null && menu.Primary_URL === currenturl &&
                                <Link to={`/${menu.Main_Parent.Primary_URL}/`} className="back-btn">{menu.Main_Parent.Label}<span> . </span></Link>
                                }
                             
                                {menu.Main_Parent && menu.Sub_Parent && menu.Primary_URL === currenturl &&
                                <Link to={`/${menu.Main_Parent.Primary_URL}/${menu.Sub_Parent.Primary_URL}/`} className="back-btn">{menu.Sub_Parent.Label}</Link>
                                }  
                                {menu.Main_Parent !=null && !props.itemlabel && !props.menulabel && menu.Primary_URL === currenturl &&
                                <Link className="back-btn">{menu.Label}</Link>                           
                                }
                                {menu.Main_Parent !=null && !props.itemlabel && props.menulabel && menu.Primary_URL === currenturl &&
                                <Link to={`/${menu.Main_Parent.Primary_URL}/${menu.Primary_URL}/`} className="back-btn">{menu.Label} <i className="icon-arrow-right"></i></Link>                           
                                }
                                </>
                                })}
                                {props.baselabel && props.baseurl &&
                                <Link to={props.baseurl} className="back-btn">{props.baselabel}<span> . </span></Link>
                                }
                                {props.itemlabel &&
                                <Link to={props.property_url?props.property_url:""} className="back-btn">{props.itemlabel}</Link>
                                }
                                {props.menulabel &&
                                <Link className="back-btn">{props.menulabel}</Link>
                                }
                           </div>
                      
           </div>
        </React.Fragment>
    )
}
export default Breadcrumbs
