/**
 * External dependencies
 */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import Estas from '../../images/ESTAS2023.png'
/**
 * Internal dependencies
 */
import "./styles/_index.scss";
import axios from "axios"
import wordsToNumbers from 'words-to-numbers';
import $ from 'jquery';
import { Link } from "gatsby"

const Reviews = (props) => {
  const [testimonials, setTestimonials] = useState([])
  const [propItems, setPropItems] = useState(false)
  const strapiconfig = {
    headers: {
      Authorization:
        `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)// could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
    getitems(url);
  }, [])

  const getitem = async url => {
    try {
      const { data } = await axios.get(url, {})
      setPropItems(data)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    var reviewkey = 'MlT0J44L1BzgHYD3YanRx37EQVCGaZghr8Jm4Y8SeUL64oUzzS7GyAGSzp7H';
    let url = `https://www.theestas.com/api/widget?api_token=${reviewkey}&with_comments=true&demo=0`;
    getitem(url)
  }, [])

  var testimonial_count = testimonials ? testimonials.length : 0;
  var rating_count = 0;
  testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
    rating_count += parseInt(wordsToNumbers(item.starRating));
  })
  var rating_avg = rating_count / testimonial_count;
  //   useEffect(() => {

  //   axios.get('//api.feefo.com/api/10/reviews/summary/all?merchant_identifier=lamborn-hill-estate-agents')
  //   .then((res) => {
  //     //console.log("RESPONSE RECEIVED: ", res);
  //     if (res.status === 200) {
  //         //console.log("aaaaaa ", res.data.meta.count);
  //         // document.getElementById('feefo_total').innerHTML = res.data.meta.count
  //         // document.getElementById('feefo_avg').innerHTML = res.data.rating.rating
  //         $("#feefo_total").html(res.data.meta.count);
  //         $("#feefo_avg").html(res.data.rating.rating);
  //         $(".home-feedback #feefo_total").html(res.data.meta.count);
  //         $(".home-feedback #feefo_avg").html(res.data.rating.rating);
  //     }
  //   })
  //   .catch((err) => {
  //     console.log("AXIOS ERROR: ", err);
  //   })
  // }, [])

  // var express = require('express')
  // var cors = require('cors')
  // var app = express()

  // app.use(cors())
  // app.get('//api.feefo.com/api/10/reviews/summary/all?merchant_identifier=lamborn-hill-estate-agents', function (req, res, next) {
  //   //res.json({msg: 'This is CORS-enabled for all origins!'})
  //   console.log("RESPONSE RECEIVED: ", res);

  // })

  return (
    <>
      <div className={classNames(`reviews`, props.className)}>
        <div className="reviews__google">
          <Link to="/about/customer-reviews">
            <div className="reviews__image">
              <i className="google"></i>
            </div>
            <p>
              <span className="reviews__rating">{parseFloat(rating_avg).toFixed(1)}/5 </span>
              from
              <span className="reviews__text"> {testimonial_count} Reviews</span>
            </p>
          </Link>
        </div>

        <div className="reviews__feefo">
          <a href={`${propItems.reviewUrl}/`} target="_blank">
            <div className="reviews__image">
              <i className="feefo"></i>
            </div>

            <p>
              <span className="reviews__rating">{`${propItems.rating}% `}</span>
              <span className="reviews__text">{propItems.reviewCountLabel}</span>
            </p>
          </a>
        </div>
      </div>
      <div className={classNames(`reviews`, props.className)} style={{ paddingTop:"15px" }}>
        <img
          src={Estas}
          alt="ESTAS2023"
          style={{ width: "75%", height: "75%" }}
        />
      </div>
    </>
  )
}

export default Reviews
