import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import {Link}  from "gatsby"

const GetCTA = (props) => {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        allMenus(sort:"Sorting:asc") {
          id
          Label
          Primary_URL
          Secondary_URL
          Main_Parent {
            id
            Label
            Primary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
          }
        }
      }
    }
  `)
  var menu_data = data.glstrapi.allMenus;
  console.log('customlink', props.ctas)
  return (
    <>
        {menu_data.map((menuitem) => {
        return<>
            {menuitem.Main_Parent === null && menuitem.Sub_Parent === null && props.link === menuitem.Primary_URL && !props.customlink &&
            <Link to={`/${menuitem.Primary_URL}/`} className={props.class}>
                {props.iconclass &&
                <i className={`${props.iconclass}`}></i>
                }
                {props.Label}{!props.hideArrow && <i className="icon-arrow-right"></i>}                
            </Link>
            }

            {menuitem.Main_Parent != null && menuitem.Sub_Parent === null && props.link === menuitem.Primary_URL && !props.customlink &&
            <Link to={`/${menuitem.Main_Parent.Primary_URL}/${menuitem.Primary_URL}/`} className={props.class}>
                {props.iconclass &&
                <i className={`${props.iconclass}`}></i>
                }
                {props.Label} {!props.hideArrow && <i className="icon-arrow-right"></i>}                
            </Link>
            }
            {menuitem.Main_Parent != null && menuitem.Sub_Parent != null && props.link === menuitem.Primary_URL && !props.customlink &&
            <Link to={`/${menuitem.Main_Parent.Primary_URL}/${menuitem.Sub_Parent.Primary_URL}/${menuitem.Primary_URL}/`} className={props.class}>
                {props.iconclass &&
                <i className={`${props.iconclass}`}></i>
                }
                {props.Label} {!props.hideArrow && <i className="icon-arrow-right"></i>}                
            </Link>
            }
        </>
        })}
        {props.customlink &&
            <Link to={`${props.customlink}/`} className={props.class}>
            {props.Label} {!props.hideArrow && <i className="icon-arrow-right"></i>}
            </Link>
        }
    </>
  )
}
export default GetCTA;
